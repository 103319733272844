.news-page {
	.hero {
		position: relative;
		margin-bottom: 50px;
		img {
			width: 100%;
		}
		h1 {
			position: absolute;
			width: max-content;
			top: 95%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #FFF;
		}
		@media screen and (max-width: 768px) {
			h1 {
				font-size: 12px!important;
			}
		}
		margin-bottom: 25px;
	}
	padding-bottom: 60px!important;
	@media screen and (max-width: 768px) {
		padding-left: 15px!important;
		padding-right: 15px!important;
	}
	.heading {
		h1 {
			padding-bottom: 1em!important;
			@media screen and (max-width: 768px) {
				font-size: 30px;
			}
		}
		p {
			font-size: 24px;
			margin-bottom: 50px;
			@media screen and (max-width: 768px) {
				font-size: 20px;
				font-weight: 500;
				line-height: 1.5;
			}
		}
	}

	.post {
		figure {
			height: 300px;
			background-size: cover;
			background-position: center;
			border-bottom: 5px solid $red;
		}
		p {
			margin-bottom: 5px;
			font-size: 12px;
		}
		h5 {
			margin-bottom: 40px;
		}
		a:hover {
			color: $red;
		}
	}
	
}

.posts__links {
	background-color: $light_grey;
	padding: 40px;
	input {
		border: none;
		width: 100%;
		border-radius: 15px;
		padding: 10px;
	}
	h4 {
		margin-bottom: 20px;
		color: $red;
	}
	hr {
		border-top: 2px solid white;
		margin: 40px 0;
	}

	ul {
		list-style-image: url(/images/caret.svg);
		padding: 1rem;
	}
}

.post__share {
	@media screen and (max-width: 768px) {
		margin-bottom: 40px;
	}
	a {
		margin-right: 10px;
		img {
			max-width: 40px;
		}
	}
}

.single-post {
	padding-top: 150px!important;
	margin-bottom: 60px;
	@media screen and (max-width: 768px) {
		padding-top: 100px!important;
	}
	h1 {
		margin-bottom: 15px;
		@media screen and (max-width: 768px) {
			font-size: 30px;
		}
	}
	.post__date {
		margin-bottom: 25px;
	}
}

input#searchsubmit {
	margin-top: 10px;
}