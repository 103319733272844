.public-access {
	margin-bottom: 60px;
	@media screen and (max-width: 500px) {
		margin-bottom: 0;
        .container {
            padding: 0;
            .page-intro {
            	margin-bottom: 25px;
            }
        }
    }
	&.single h1 {
		padding-bottom: 10px!important;
	}
	.top {
		margin-bottom: 50px;
		@media screen and (max-width: 500px) {
			margin-bottom: 0;
		}
	}
	.downloads {
		@media screen and (max-width: 500px) {
			margin-top: 25px;
		}
		a {
			display: block;
			font-size: 20px;
			margin-bottom: 20px;
			@media screen and (max-width: 500px) {
				font-size: 16px;
			}
			img {
				max-width: 40px;
				margin-right: 10px;
				@media screen and (max-width: 500px) {
					max-width: 35px;
				}
			}
		}
	}
	.main {
		margin-bottom: 50px;
		p {
			font-size: 18px;
			line-height: 1.5;
			@media screen and (max-width: 500px) {
				font-size: 16px;
			}
		}
	}

	.public-access-card {
		img {
			margin-bottom: 20px;
			@media screen and (max-width: 500px) {
				margin-bottom: 10px;
			}
		}
		p:first-of-type {
			margin-bottom: 10px;
		}
		h4 {
			margin-bottom: 20px;
		}
	}
}