.category {
	.hero {
		height: 80vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 500px) {
			height: 200px;
		}
		@media screen and (max-width: 768px) {
			height: 400px;
		}
		h1 {
			color: white;
			font-size: 90px;
			@media screen and (max-width: 768px) {
				font-size: 32px;
			}
		}
	}

	.intro {
		padding: 80px 0;
		@media screen and (max-width: 768px) {
			padding: 50px 0;
		}
		.head {
			padding-right: 50px;
			@media screen and (max-width: 768px) {
				padding-right: 15px;
			}
			h2 {
				font-size: 48px;
				margin-bottom: 40px;
				span {
					font-family: museo;
					font-weight: bold;
				}
				@media screen and (max-width: 768px) {
					font-size: 30px;
				}
			}
			p {
				font-size: 18px;
				@media screen and (max-width: 768px) {
					font-size: 20px;
				}
			}
		}
	}

	.quote {
		display: flex;
		align-items: end;
		margin-bottom: 50px;
		img {
			width: 20px;
			margin-right: 10px;
		}
		img, p {
			display: inline;
		}
		p {
			font-size: 16px;
		}
	}

	.featured {
		padding: 100px;
		@media screen and (max-width: 768px) {
			padding: 50px 20px;
		}
		h2 {
			font-size: 40px;
			margin-bottom: 20px;
			@media screen and (max-width: 500px) {
				margin-top: 25px;
				font-size: 30px;
			}
			@media screen and (max-width: 768px) {
				font-size: 30px;
			}
		}
		p {
			font-size: 20px;
			margin-bottom: 10px;
			@media screen and (max-width: 768px) {
				font-size: 1rem;
				strong {
					display: block;
				}
			}
		}
		@media screen and (max-width: 768px) {
			.img {
				display: flex;
				align-items: center;
			}
		}
		.angle-button {
			margin-top: 40px;
		}
		&:nth-of-type(1) {
			background-color: $red;
			h2 {
				color: white;
			}
			p {
				color: white;
				strong {
					color: $dark_grey;
					
				}
			}
		}
		&:nth-of-type(2) {
			background-color: $dark_grey;
			h2 {
				color: white;
			}
			p {
				color: white;
				strong {
					color: $red;
				}
			}
		}
		&:nth-of-type(3) {
			background-color: $light_grey;
			p {
				strong {
					color: $red;
				}
			}
		}
		.data {
			display: flex;
			align-items: center;
		}
	}

	.other {
		padding-bottom: 60px;
		h2 {
			padding: 50px 0;
			font-size: 40px;
			@media screen and (max-width: 768px) {
				font-size: 30px;
				padding: 35px 0;
			}
		}
	}

	
	
}
