.team {
    padding-bottom: 60px;
    @media screen and (max-width: 768px) {
        
        padding-bottom: 15px;
        
    }
    .team-member {
        p {
            font-weight: bold;
            font-family: museo;
            font-size: 20px;
            padding: 20px 0;
        }
        color: $dark_grey;
        &:hover {
            color: red;
        }

    }
    .details {
        display: none;
        background-color: $dark_grey;
        width: 100%;
        max-width: 1200px;

        @media screen and (max-width: 768px) {
            padding: 15px!important;

            img.w-100 {
                margin-bottom: 25px;
            }
        }
        
        .row {
            padding: 50px;
            @media screen and (max-width: 768px) {
                padding: 0;
            }
        }
        p, h2, strong {
            color: white;
        }

        button.fancybox-button.fancybox-close-small {
            background: black;
            color: white;
            border-radius: 50px;
            top: 25px;
            right: 25px;
            width: 60px;
            height: 60px;
        }

        .fancybox-button svg path {
            fill: $red;
        }

        .contact-details {
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            a {
                display: flex;
                flex-basis: 45%;
                color: white;
                align-items: center;
                padding-right: 10px;
                margin-bottom: 20px;
                img {
                    width: 50px;
                    margin-right: 10px;
                }
            }
        }
    }
}