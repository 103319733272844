div.container.contact-us {
    padding-top: 200px;
    @media screen and (max-width: 500px) {
        padding-top: 100px;
        br {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 100px;
    }
    h1 {
        font-weight: 300;
        span {
            font-family: museo;
            color: $red;
            font-weight: 700;
        }
        padding-bottom: 50px;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
            font-size: 30px;
            padding-bottom: 35px;
        }
    }
    .page-intro {
        p {
            font-size: 20px;
            font-weight: 500;
        }
        margin-bottom: 60px;
    }

    .locations {
        padding-bottom: 60px;
    }
    .location {
        &:first-of-type {
            border-right: 1px solid $dark_grey;
            @media screen and (max-width: 500px) {
                border-right: none;
                padding-bottom: 50px;
            }
        }
        img {
            width: 100%;
            max-width: 100px;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                max-width: 60px;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 30px;
            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
        p {
            font-size: 20px;
            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }
        .contact-details {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                font-family: museo-sans;
                font-weight: 700;
                color: $dark_grey;
                font-size: 20px;
                margin-bottom: 20px;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
                img {
                    max-width: 50px;
                    margin-bottom: 0;
                    margin-right: 20px;
                    @media screen and (max-width: 768px) {
                        max-width: 40px;
                    }
                }
            }
        }
    }
    .row.final {
        padding-bottom: 120px;
        @media screen and (max-width: 768px) {
            padding-bottom: 50px;
        }
        .toggles {
            position: absolute;
            z-index: 100;
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
        .map-toggle {
            border: none;
            color: white;
            &:disabled {
                background-color: $red;
            }
            &:last-of-type {
                margin-left: -15px;
            }
        }
        .map {
            display: none;
            &.visible {
                display: block;
            }
        }
    }
    
}