nav.nav--main {
	position: fixed;
	width: 100%;
	padding: 50px 0;
	z-index: 2;
	transition: all .5s ease;
	
	@media screen and (max-width: 1080px) {
		padding: 25px 0px;
		.container .row {
			padding: 0 15px;
		}
	}
	a.logo {
		width: 100%;
		max-width: 300px;
		height: 35px;
		background-image:url(/images/logo.svg);
		background-repeat:no-repeat;
		background-size: contain;
		@media screen and (max-width: 1080px) {
			height: 26px;
			max-width: 200px;
		}
	}
	button.burger {
		width: 50px;
	    background-color: transparent;
	    background-image: url(/images/burger-dark.svg);
	    background-size:contain;
	    background-repeat: no-repeat;
	    background-position: center;
	    border: none;
	    @media screen and (max-width: 1080px) {
			width: 38px;
			padding-right: 15px;
		}
	}
	&.open {
		a.logo {
			background-image:url(/images/logo-white.svg);
		}
		button.burger {
			background-image: url(/images/close-white.svg);
		}
	}

	&.scrolled {
		background-color: white;
		padding: 25px 0;
		box-shadow: 5px 5px 5px rgba(0,0,0,0.05);
		button.burger {
			background-image: url(/images/burger-dark.svg);
		}
	}
	.col {
		display: flex;
		justify-content: space-between;
	}

}


.menu {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-image: url(/images/red-bg.svg);
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity .2s ease;
	pointer-events: none;
	&.open {
		opacity: 1;
		z-index: 1;
		pointer-events: auto;
		@media screen and (max-width: 500px) {
			align-items: flex-start;
		    padding-top: 150px;
		}
	}
	.grid-container {
		width: 60vw;
		height: 33vh;
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	  grid-template-rows: repeat(5, 1fr);
	  gap: 1px 1px;
	  grid-template-areas: ". ." ". ." ". ." ". .";
	  grid-auto-flow: column;
	  @media screen and (max-width: 500px) {
	  	    display: flex;
		    flex-direction: column;
		    height: auto;
		    width: 90vw;
	  }
	  .menu--link {
	  	display: flex;
	  	align-items: center;
	  	@media screen and (max-width: 1080px) {
	  		padding-bottom: 10px;
	  	}
	  	span {
	  		color: white;
	  	}
	  	a {
	  		font-size: 34px;
	  		margin-left: 50px;
	  		color: white;
	  		text-decoration: none;
	  		transition: color .2s ease;
	  		&:hover {
	  			color: $dark_grey;
	  		}
	  		@media screen and (max-width: 1080px) {
	  			font-size: 28px;
	  			margin-left: 30px;
	  		}

	  		@media screen and (max-width: 500px) {
	  			font-size: 20px;
	  			margin-left: 30px;
	  		}
	  	}
	  }
	}
	.bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	    display: flex;
	    padding: 25px 50px;
	    @media screen and (max-width: 500px) {
	    	position: fixed;
	    	bottom: 10px;
			padding: 15px;
			justify-content: space-between;
			div:first-of-type {
				display: none;
			}
			div:nth-of-type(2) {
				display: none;
			}
			a:last-of-type {
				img {
					margin-right: 0;
				}
			}
		}
	    div {
	    	flex-basis: 50%;
	    	p, a {
	    		color: white;
	    		display: block;
	    		font-size: 18px;
	    		@media screen and (max-width: 1080px) {
	    			font-size: 18px;
	    		}
	    		@media screen and (max-width: 500px) {
	    			font-size: 16px;
	    		}
	    	}
	    }
	    div {
	    	flex-basis: 25%;
	    	padding: 10px;
	    }
	    .social {
	    	justify-content: flex-end;
	    }

	}


	@media all and (-ms-high-contrast:none) {
	  .grid-container {
	    display: -ms-grid;
	    -ms-grid-columns: repeat(2, 1fr);
	    -ms-grid-rows: repeat(4, 1fr);
	  }

	}
}

.planning .nav--main, .heritage .nav--main {
	background-color: white;
	padding: 25px 0;
	box-shadow: 5px 5px 5px rgba(0,0,0,0.05);
	button.burger {
		background-image: url(/images/burger-dark.svg);
	}
	&.open {
		background-color: transparent;
		box-shadow: none;
	}
}