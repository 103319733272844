.case-study {
	img.hero {
		max-height: 90vh;
		object-fit: cover;
	}
	.nav-bar {
		height: 64px;
		background-color: $dark_grey;
		width: 100%;
		@media screen and (max-width: 500px) {
			height: 48px;
			overflow: hidden;
		}
		.link {
			background-color: $red;
			display: inline-block;
			padding: 20px 50px;
			-ms-transform: skewX(20deg);
		  	-webkit-transform: skewX(20deg);
		  	transform: skewX(20deg);
		  	margin-left: -20px;
		  	@media screen and (max-width: 500px) {
		  		padding: 13px 50px;
		  	}
		  	a {
		  		-ms-transform: skewX(-20deg);
			  	-webkit-transform: skewX(-20deg);
			  	transform: skewX(-20deg);
			  	display: inline-block;
			  	color: white;
		  	}
		}
	}

	.main {
		padding: 60px 15px;
		@media screen and (max-width: 500px) {
			padding: 35px 15px;
		}
		.intro {
			padding-right: 30px;
			h1 {
				font-size: 52px;
				font-weight: 700;
				line-height: 1.1;
				margin-bottom: 40px;
				@media screen and (max-width: 500px) {
					font-size: 33px;
					margin-bottom: 28px;
				}
			}
			p {
				font-size: 20px;
				font-weight: bold;
				@media screen and (max-width: 500px) {
					font-size: 18px;
				}
			}
		}
		.details {
			h3 {
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 6px;
				color: $red;
				@media screen and (max-width: 500px) {
					font-size: 18px;
				}
			}
			p {
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 25px;
				@media screen and (max-width: 500px) {
					font-size: 18px;
				}
			}
		}
		.content {
			padding: 0px 30px 50px 15px;
			@media screen and (max-width: 500px) {
				padding: 0px 15px 15px 15px;
			}
		}
	}
	.gallery {
		padding-top: 60px;
		padding-bottom: 120px;
		background-color: $light_grey;
		@media screen and (max-width: 500px) {
			padding-top: 0px;
			padding-bottom: 50px;
		}
		h2 {
			padding: 50px 0px;
			font-size: 40px;
			@media screen and (max-width: 500px) {
				font-size: 30px;
				padding: 35px 0;
			}
		}
		img {
			border-bottom: 5px solid $red;
			max-height: 250px;
		    object-fit: cover;
		    margin-bottom: 20px;
		    width: 100%;
		}
	}

	.related {
		background-color: $red;
		padding: 40px 0;
		color: white;
		@media screen and (max-width: 500px) {
			h2 {
				font-size: 1.6rem;
			}
		}
	}
}