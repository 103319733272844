footer.footer {
	background-color: #282827;

	.col-md-3 {
		padding: 0 50px;
		@media screen and (max-width: 768px) {
			padding: 0 15px	;
		}
		ul {
			list-style: none;
		}
		&:not(:last-of-type) {
			border-right: 1px solid white;
			@media screen and (max-width: 500px) {
				border: none;
				ul {
					padding-left: 0;
				}
			}
			@media screen and (max-width: 768px) {
				ul {
					padding-left: 10px;
				}
			}
		}
		&.contact {
			img {
				margin-bottom: 1rem;
			}
		}
	}
	p {
		color: white;
	}
	a {
		color: white;
		text-decoration: none;
		margin-bottom: 1rem;
		display: block;
		&:hover {
			color: $red;
		}
	}
	.row {
		padding: 50px 0;

		@media screen and (max-width: 500px) {
			padding: 50px 0;
			.contact {
				p {
					margin-bottom: 1.6rem;
				}
				img {
					max-width: 190px;
				}

			}
			.col-md-3 ul {
				column-count: 2;
				border-bottom: 1px solid #525252;
				padding-bottom: .7rem!important;
				margin-bottom: 20px!important;
				a.extra-mobile-padding {
					padding-bottom: 30px;
				}
			}
		}

		.flex {
			display: flex;
			justify-content: space-between;
			a {
				width: 100%;
			    text-align: center;
				padding: 0 20px;
				&:not(:last-of-type) {
					border-right: 1px solid white;
					@media screen and (max-width: 500px) {
						border: none;
					}
				}
			}
			@media screen and (max-width: 500px) {
				flex-direction: column;
			}
		}
		
	}
	.container-fluid.logos {
		background-color: $dark_grey;
		.logo-strip {
			display: flex;
			justify-content: center;
			gap: 25px;
			@media screen and (max-width: 500px) {
				flex-wrap: wrap;
			}
			img {
				max-height: 50px;
				padding: 0 10px;
				@media screen and (max-width: 500px) {
				    flex-basis: 100%;
				    margin: 10px 0;
				}
			}
		}
	}
	.container-fluid.copyright {
		background-color: $black;
		.row {
			padding: 20px 0;
		}
	}
	.made-by {
		font-size: 12px;
		display: block;
		text-align: right;
		background-color: $black;
		color: white;
		a {
			display: inline;
			color: #ED1745;
		}
	}
}