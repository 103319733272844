$red: #ED1745;
$dark_grey: #1D1D1B;
$light_grey: #E6E7E8;
$black: #171717;

@import './_nav';
@import './_footer';
@import './_contact';
@import './_team';
@import './_public-access';
@import './_category';
@import './_case-study';
@import './_news';

* {
	font-family: museo-sans, sans-serif;
	font-weight: 300;
	font-style: normal;
	color: $dark_grey;
}

a {
	color: $dark_grey;
	transition: color .2s ease;
	&:hover {
		text-decoration: none;
	}
}

h1,h2,h3,h4,h5 {
	font-family: museo, serif;
	font-weight: 500;
	font-style: normal;
}

.locked {
    overflow: hidden;
    height: 100%;
}

.filters .col-md-12 {
	display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    &.filter-button-group {
    	@media screen and (max-width: 500px) {
			flex-wrap: wrap;
			justify-content: space-between;
			.angle-button {
				flex-basis: 43%;
				width: 43%;
				&:first-of-type {
					flex-basis: 100%;
				}
			}
		}
    }
    .angle-button {
    	width: 90%;
    }
}

a.cs {
	margin-bottom: 20px;
	&:hover {
		color: $red;
	}
	p {
		font-weight: bold;
		font-size: 20px;
		padding: 10px 0;
	}
	img {
		border-bottom: 5px solid $red;
		height: 250px;
		object-fit: cover;
		@media screen and (max-width: 768px) {
			height: 150px;
		}
	}
}

div.container-fluid {
	padding: 0 50px;
	@media screen and (max-width: 768px) {
		padding: 0 15px;
		.container {
			padding: 0;
		}
	}
	&.single {
		padding-top: 200px;
		@media screen and (max-width: 768px) {
			padding-top: 100px;
		}
		h1 {
			font-weight: 300;
			span {
				font-family: museo;
				color: $red;
				font-weight: 700;
			}
			padding-bottom: 50px;
			margin-bottom: 0;
			@media screen and (max-width: 768px) {
				font-size: 32px;
				line-height: 1.2;
				padding-bottom: 10px;
			}
		}
		.page-intro {
			p {
				font-size: 20px;
				font-weight: 500;
				@media screen and (max-width: 768px) {
					font-size: 18px;
					line-height: 1.5;
				}
			}
			margin-bottom: 60px;
			@media screen and (max-width: 768px) {
	            margin-bottom: 15px;
	        }
		}
	}
}

.home--intro {
	background-color: $dark_grey;
	position: relative;
	.slick-container {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    
		@media screen and (max-width: 768px) {
			height: 40vh;
			top: 75px;
		}
		@media screen and (max-width: 500px) {
			height: 30vh;
			top: 25px;
		}
	    .slick-track {
	    	display: flex;
		    align-items: center;
	    }
	    .slick-slider {
	    	width: 60vw;
	    	@media screen and (max-width: 768px) {
				width: 90vw;
			}
	    }
	    p {
	    	text-align: center;
	    	color: white;
	    	font-size: 100px;
	    	font-family: museo;
			font-weight: bold;
			line-height: 108px;
			@media screen and (max-width: 1280px) {
				font-size: 60px;
				line-height: 1.2;
			}
			@media screen and (max-width: 768px) {
				font-size: 32px;
				line-height: 1.2;
			}
	    	span {
				color: $red;
				font-family: museo;
				font-weight: bold;
			}
	    }
	}
}



.home--chevron {
	padding: 50px 0;
	@media screen and (max-width: 500px) {
		padding: 35px 15px;
	}
	p.lead {
		font-family: museo;
		font-size: 42px;
		@media screen and (max-width: 768px) {
			font-size: 25px;
			line-height: 1.4;
		}
		strong {
			color: $red;
			font-family: museo;
		}
	}
}
.row.reverse {
	@media screen and (max-width: 500px) {
		flex-direction: column-reverse;
	}
}
.container-fluid.home--section {
	padding: 100px;
	@media screen and (max-width: 768px) {
		padding: 50px 5vw;
	}
	.row {
		margin: 0;
		
	}
	.text-container {
		display: flex;
		align-items: center;
		.text {
			padding-left: 50px;
			width: 70%;
			@media screen and (max-width: 500px) {
				padding-left: 0;
			}
			@media screen and (max-width: 1400px) {
				width: 100%;
			}
			
		}
	}
	h2 {
		font-size: 48px;
		color: white;
		margin-bottom: 1rem;
		@media screen and (max-width: 768px) {
			font-size: 30px;
		}
	}
	p {
		font-size: 20px;
		color: white;
		margin-bottom: 1.5rem;
		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}
	&.home--red {
		background-color: $red;
	}
	&.home--dark {
		background-color: $dark_grey;
	}
	&.home--grey {
		background-color: $light_grey;
		h2, p {
			color: $dark_grey;
		}
	}
	&.home--white {
		h2, p {
			color: $dark_grey;
		}
		.col-md-5 {
			padding: 0 50px;
		}
	}
}

.angle-button {
	-ms-transform: skewX(-20deg);
  	-webkit-transform: skewX(-20deg);
  	transform: skewX(-20deg);
	background-color: black;
	text-align: center;
	text-transform: uppercase;
	padding: 10px 20px;
	margin: 10px;
	display: inline-block;
	@media screen and (max-width: 768px) {
		width: 95%;
		font-size: .9rem;
	}
	&.active, &.red {
		background-color: $red;
	}
	.angle-button-inner {
		-ms-transform: skewX(20deg);
		-webkit-transform: skewX(20deg);
		transform: skewX(20deg);
		display: inline-block;
	  	color: white;
	}
	button {
		background: transparent;
		border: none;
		&:active, &:focus {
			outline: none;
		}
	}
}

div.social {
	display: flex;
	align-items: center;
	justify-content: start;
	img {
		height: 27px;
		margin-right: 20px;
		@media screen and (max-width: 500px) {
			height: 26px;
			margin-right: 26px;
		}
	}
}

.wpcf7-form {
    label {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        width: 100%;
        span {
            display: block;
            margin-top: 10px;
        }
    }
    p {
        input,
        textarea {
            background-color: $light_grey;
            border: none;
            padding:10px;
            width: 100%;
        }
    }
    p:last-of-type {
        -ms-transform: skewX(-20deg);
        -webkit-transform: skewX(-20deg);
        transform: skewX(-20deg);
        background-color: black;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 20px;
        margin: 10px;
        display: inline-block;
        @media screen and (max-width: 500px) {
            width: 90%;
        }
        input[type=submit] {
            -ms-transform: skewX(20deg);
            -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
            display: inline-block;
            color: white;
            background:transparent;
            border: none;
            text-transform: uppercase;
            padding: 5px 10px;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
    .ajax-loader {
        display: none!important;
    }
}

.container-fluid.needhelp {
	background-color: $light_grey;
	padding-top: 60px;
	padding-bottom: 60px;
	@media screen and (max-width: 768px) {
		padding: 50px 15px;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 40px;
		@media screen and (max-width: 768px) {
			font-size: 30px;
			margin-bottom: 25px;
		}
	}
	p {
		font-size: 20px;
		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	.wpcf7 {
		input:not([type=submit]), textarea {
			background-color: white!important;
		}
		p:nth-of-type(1), p:nth-of-type(2) {
			width: 45%;
			display: inline-block;
			@media screen and (max-width: 768px) {
				width: 100%;
				display: block;
			}
		}
		p:nth-of-type(1) {
			margin-right: 9%;
		}
		label {
			width: 100%;
		}
		textarea {
			width: 100%;
		}
	}
	&.white {
		background-color: white;
		input:not([type=submit]), textarea {
			background-color: $light_grey!important;
		}
	}
}

.fancybox-button svg path {
	fill: white;
}



/*# sourceMappingURL=main.css.map */
